// //all jquery ux dom manipulations
var animateCount = function(cls , count) {
  var el = $('.count-animated.' + cls);
  if (!el) {
    return;
  }

  el.text(count);
  el.prop('Counter', 0).animate({
    Counter: count
  }, {
    duration: 500,
    easing: 'swing',
    step: function(now) {
      $(this).text(Math.ceil(now));
    }
  });
};
